import React, {PureComponent} from 'react';

import Header from '../Header';
import Footer from '../Footer';
import { withRouter } from 'react-router';
class Container extends PureComponent {
    render() {
        return (
            [
            <Header key={0}/>,
            this.props.children,
            <Footer key={1}/>
            ]
        );
    }
}

export default withRouter(Container);