import React, {PureComponent} from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../Logo';

const VENUE_LINK = 'https://www.sherbornesports.co.uk/';
const VENUE_LINK_TEXT = 'Sherborne Sports Centre';
const next = (index, base) => {
    const m = base.clone();
    while (m.isoWeekday() !== index) {
        m.add(1, 'day');
    }
    return m;
}
const ICON_SIZE='45px';
const ICON_STYLE = {color: '#0056b3', height: ICON_SIZE, width: ICON_SIZE};

const Session = (props) => {
    const { session } = props;
    const isoWeekday = session.isoWeekday();
    const start = session, end = session.clone();
    start.hours(isoWeekday === 7 ? 11 : 20).minutes(0);
    end.hours(isoWeekday === 7 ? 13 : 21).minutes(0);
    return  (
        <div itemScope itemType="http://schema.org/Event">
            <FontAwesomeIcon icon="calendar-alt" />&nbsp;
            <time itemProp="startDate" dateTime={start.format()}>
            {start.format('dddd Do MMM')} &nbsp;
            <small>
                {start.format('LT')} - {end.format('LT')}
            </small>
            </time>
        </div>
    )
};

export default class Home extends PureComponent {
    render() {
        const today = moment();
        const a = next((today.isoWeekday() == 0 || today.isoWeekday() > 3) ? 3 : 7, today).minutes(0);
        const b = next(a.isoWeekday() == 3 ? 7 : 3, today);
        const first = a > b ? b : a;
        const second = a > b ? a : b;
        const nextSession = first ;
        const secondSession = second;
        
        return (
            <section className={'home'}>
                <div className={'home-left'}>                
                    <h1>Yeovil Wildcats Inline Hockey</h1>
                    <p>
                        Next Training Sessions:
                    </p>
                    <Session session={nextSession} />
                    <Session session={secondSession} />
                    Find us at <a href={VENUE_LINK}>{VENUE_LINK_TEXT}</a>
                    <br/>
                    <br/>
                    <a href="https://www.facebook.com/WildcatsHockey/" rel="noopener" target="_blank">
                        <FontAwesomeIcon icon={['fab', 'facebook-square']} style={ICON_STYLE} />
                    </a>&nbsp;
                    <a href="https://www.instagram.com/yeovilwildcats/" rel="noopener" target="_blank">
                        <FontAwesomeIcon icon={['fab', 'instagram']} style={ICON_STYLE}/>
                    </a>
                    
                </div>
                <div className={'home-right'}>
                    <img className={'team-logo'} src={require('../wildcats-full.svg')} />
                </div>
            </section>
            
        );
    }
}