import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

export default () => (
    <footer>
        <ul>
            <li><NavLink to={'/terms'}>Website Terms of Use</NavLink></li>
            <li><NavLink to={'/privacy'}>Privacy Policy</NavLink></li>
            <li>&copy; Yeovil Wildcats {moment().format('YYYY')}</li>
        </ul>
    </footer>
);
