import React, {PureComponent} from 'react';
import ComingSoon from '../ComingSoon';

export default class Media extends PureComponent {
    render () {
        return (
            <section>
                <ComingSoon />
            </section>
        );
    }
}
