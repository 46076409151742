import React, {PureComponent} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class News extends PureComponent {
    render () {
        return (
            <section>
                <h1>News</h1>
                <h2><FontAwesomeIcon icon="calendar-alt"/> Result - BRIHA 10th Feb</h2>
                <p>Tough day in the office today for the Wildcats.<br/>
                Game 1 saw us take on the Kernow Wizards, a physical game saw the Wizards take a early lead 
                and they didn't look back. The game finished 11-1 to the Wizards.<br/>
                Game 2 was against the Plymouth Stars, a close game saw the Wildcats take a lead and then 
                give it up 4-3 at half time. The game ended 9-4 in favour of the Stars.
                </p>
            </section>
        );
    }
}
