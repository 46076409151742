import React, { PureComponent, Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFirebase } from "../Firebase";

const RESULT_LABELS = {
    "win": "WIN",
    "otw": "OTW",
    "otl": "OTL",
    "draw": "DRAW",
    "loss": "LOSS"
};

class Result extends PureComponent {
    render() {
        if (!this.props.match.score) {
            return null
        }
        const { wdl, score } = this.props.match;
        const label = RESULT_LABELS[wdl];
        return (
            <span>
                {label} { score.wildcats } - { score.opponent }
            </span>
        );
    }
}

class Opponent extends PureComponent {
    state = {
        loaded: false,
        opponent: {}
    };

    async componentDidMount() {
        const { opponent } = this.props;
        const data = await opponent.get();
        this.setState({ loaded: true, opponent: data.data() })
    }

    render() {
        const { loaded, opponent } = this.state;
        if (!loaded) {
            return null;
        }
        if (opponent.href) {
            return <span className={'opponent_name'}><a href={opponent.href} rel={'noreferrer'}>{ opponent.name }</a></span>;
        }
        return <span>{ opponent.name }</span>;
    }
}

class Matches extends PureComponent {
    state = {
        loaded: false,
        matches: [],
    };

    async componentDidMount() {
        const matchesCollection = await this.props.matches.orderBy('faceoff', 'asc').get();
        const matches = matchesCollection.docs.map(doc => doc.data());
        this.setState({ loaded: true, matches });
    }

    render() {
        const { matches } = this.state;
        let i = 0;
        return (
            <div className={'matches'}>
                { matches.map(match => (
                    <div key={ ++i } className={ 'match' } itemScope={ 'event' }
                         itemType={ 'http://schema.org/Event' }>
                        <FontAwesomeIcon icon={ 'stopwatch' }/>
                        <time dateTime={ moment(match.faceoff.toDate()).format() }>
                            { moment(match.faceoff.toDate()).format('LT') }
                        </time>
                        <Opponent opponent={ match.opponent }/>
                        <Result match={match} />
                    </div>
                )) }
            </div>
        );
    }
}

class FixturesBase extends Component {
    state = {
        dates: [],
        loaded: false,
        fixtures: [],
    };

    async setStateAsync(state) {
        console.info('ss');
        return new Promise(resolve => this.setState(state, resolve));
    }

    componentWillMount() {
    }

    async addFixture(fixture) {
        const { fixtures } = this.state;
        fixtures.push(fixture);
        console.info('added fixture', fixtures);
        return this.setStateAsync({ fixtures });
    }

    async componentDidMount() {
        const collection = this.props.firebase.firestore.collection('fixtures').orderBy('date', 'asc');
        collection.onSnapshot(async snapshot => {
            snapshot.forEach(async doc => {
                const { fixtures } = this.state;
                const { date, location } = doc.data();
                const matches = doc.ref.collection('matches');
                await this.addFixture({ date: date.toDate(), location, matches });
            });
        });
    }


    render() {
        const { fixtures } = this.state;
        let f = 0;
        return (
            <section className={ 'fixtures-list' }>
                <h1>Fixtures</h1>

                { fixtures.map(fixture => (
                    <div className={'fixture'} key={ ++f }>
                        <h2>
                            <a href={'http://www.briha.co.uk/southwestdivision.html'} rel={'noreferrer'}>
                                <img className={'league-logo'} src={'https://firebasestorage.googleapis.com/v0/b/yeovil-wildcats.appspot.com/o/briha-100x100.png?alt=media&token=8fd3add3-5943-42ad-b8c8-e687f6d74f8f'} />
                            </a>
                            <FontAwesomeIcon icon="calendar-alt"/>
                            &nbsp;
                            { moment(fixture.date).format('MMM Do YYYY') }
                        </h2>
                        <p>{fixture.location}</p>
                        <Matches matches={ fixture.matches }/>
                    </div>)
                ) }
            </section>
        );
    }
}

const Fixtures = withFirebase(FixturesBase);
export default Fixtures;
