import React, {PureComponent} from 'react';
import ComingSoon from '../ComingSoon';

export default class Contact extends PureComponent {
    render () {
        return (
            <section>
                <h1>Contact Us</h1>
                <div className={'contact_card'}>
                    <h2>Captain</h2>
                    <p>Mike Tucker</p>
                    <p>Email: <a href={'mailto:mike@yeovilwildcats.co.uk'}>mike@yeovilwildcats.co.uk</a></p>
                </div>
            </section>
        );
    }
}
