import React, { PureComponent } from 'react';
import Container from './Components/Container';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import Home from './Home';
import Fixtures from './Fixtures';
import Media from './Media';
import News from './News';
import Contact from './Contact';
import Terms from './Terms';
import Privacy from './Privacy';
import Header from './Header';
import {FacebookProvider} from 'react-facebook';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faTrophy, faStopwatch } from '@fortawesome/free-solid-svg-icons';

import {faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(faCalendarAlt);
library.add(faTrophy);
library.add(faStopwatch);
library.add(faFacebookSquare);
library.add(faInstagram);

export default class App extends PureComponent {
    render() {
        return (
            <FacebookProvider appId={391593844911206}>
                <BrowserRouter>
                    <Container>
                        <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/fixtures" component={Fixtures} />
                        <Route path="/media" component={Media} />
                        <Route path="/news" component={News} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/terms" component={Terms} />
                        <Route path="/privacy" component={Privacy} />
                        </Switch>
                    </Container>
                </BrowserRouter>
            </FacebookProvider>
        );
    }
}
