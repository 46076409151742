/* @global window */
import React, { PureComponent } from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import Logo from '../Logo';

const SCROLL_THRESHOLD = 10;

class HeaderComponent extends PureComponent {
    state = {
        small: false,
    };

    async setStateAsync(state) {
        return new Promise((resolve) => this.setState(state, resolve));
    }

    onScroll(e) {
        if (window.scrollY > SCROLL_THRESHOLD) {
            return this.setStateAsync({small: true});
        } else {
            return this.setStateAsync({small: false});
        }
    }

    async componentWillMount() {
        window.addEventListener('scroll', (e) => this.onScroll(e));
    }
    render() {
        const { small } = this.state;
        return (
            <header className={small ? 'small' : ''}>
                <nav>
                    <ul>
                        <li><Link to="/fixtures">Fixtures</Link></li>
                        <li><Link to="/media">Media</Link></li>
                        <li><Link to="/"><Logo /></Link></li>
                        <li><Link to="/news">News</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </header>
        )
    }    
};

const Header =  withRouter(HeaderComponent);
export default Header;
